import { io, Socket } from "socket.io-client";

export class WebSocketService {
  private socket: Socket | null = null;
  private namespace: string;
  private url: string;
  private token?: string;

  constructor(namespace: string, url: string, token?: string) {
    this.namespace = namespace;
    this.url = url;
    this.token = token;
  }

  connect(): Socket {
    if (this.socket) return this.socket;

    const connectionOptions = {
      transports: ["websocket"],
      path: `/ws${this.namespace}`,
      auth: { token: this.token },
      withCredentials: true,
    };

    this.socket = io(this.url, connectionOptions);

    this.socket.on("connect", () =>
      console.info(`[WebSocket Connected - ${this.namespace}]`),
    );

    this.socket.on("connect_error", (error: Error) =>
      console.error(`[WebSocket Error - ${this.namespace}]:`, error.message),
    );

    return this.socket;
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      console.info(`[WebSocket Disconnected - ${this.namespace}]`);
      this.socket = null;
    }
  }
}
