import Grid from "@mui/material/Grid";
import { useAuth } from "../../core/context/auth.context";
import useScreenSize from "../../core/hooks/use-screen-size";
import useUserOs from "../../core/hooks/use-user-os";
import UserDebtsListComponent from "../user-debts-list.component";
import UserService from "../../core/service/user.service";
import { useQuery } from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import formatCurrency from "../../core/helpers/format-currency.helper";

export default function OwedToMeTab() {
  const { user } = useAuth();
  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const innerContainerSize = screenSize.height - (isIOS ? 180 : 165); // magic numbers

  const userService = new UserService();

  const { data: userStats, refetch } = useQuery({
    queryKey: [`singleUserData`, { userId: user!.id }],
    queryFn: () => userService.getStats(user!.id),
    initialData: undefined,
  });

  const onDebtChange = async () => {
    await refetch();
  };

  return (
    <Grid
      item
      xs={12}
      height="80vh"
      style={{
        backgroundColor: "#aab1db",
        boxShadow: "1px 1px 3px rgb(0 0 0 / 40%)",
        padding: 15,
        borderRadius: 5,
      }}
    >
      <Typography
        textAlign="center"
        style={{
          textShadow: "none",
          fontWeight: 300,
          padding: 10,
          borderBottom: "1px solid rgba(255,255,255,.6)",
          marginBottom: 10,
          color: "#fff",
        }}
      >
        Total owed to me:{" "}
        <b style={{ color: "#47528c" }}>
          {formatCurrency(userStats?.data?.owed, "USD")}
        </b>
      </Typography>
      <UserDebtsListComponent
        height={innerContainerSize - 120}
        type="to-user"
        onDebtChange={onDebtChange}
        pageSize={Math.floor((innerContainerSize - 100) / 80)}
        noItemsText="No player owes you"
      />
    </Grid>
  );
}
