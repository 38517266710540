import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRow,
} from "@mui/x-data-grid";
import { BaseServiceInterface } from "../service/base.service";
import InlineLoadingComponent from "./inline-loading.component";
import { useToast } from "../hooks/use-toast.hook";

const DEFAULT_TABLE_ITEMS_COUNT = 10;

const CustomGridRow = (props: any) => {
  return <GridRow {...props} data-test-id="test-row" />;
};

interface TableComponentProps<I> {
  service: BaseServiceInterface<I>;
  columns: GridColDef[];
  filters: { [key: string]: any };
  checkboxSelection?: boolean;
  disableColumnMenu?: boolean;
  disableColumnSelector?: boolean;
  disableColumnFilter?: boolean;
  onRowClick?: (row: any) => void;
  getRowId?: (row: any) => string;
  height?: number | string;
  className?: string;
  refresh?: any;
}

const TableComponent: FC<TableComponentProps<any>> = ({
  service,
  columns,
  filters,
  disableColumnMenu,
  disableColumnSelector,
  disableColumnFilter,
  checkboxSelection,
  onRowClick,
  height,
  getRowId,
  className,
  refresh = "",
}) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_TABLE_ITEMS_COUNT,
  });

  const [items, setItems] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const { showToast } = useToast();

  const serializedFilters = JSON.stringify({
    ...filters,
    take: paginationModel.pageSize,
    skip: paginationModel.page * paginationModel.pageSize,
  });

  const { data, isFetching, error, refetch } = useQuery({
    queryKey: [`table${service}`, serializedFilters],
    queryFn: () =>
      service.list({
        ...filters,
        take: paginationModel.pageSize,
        skip: paginationModel.page * paginationModel.pageSize,
      }),
  });

  useMutation({
    onError: (error: Error) => {
      showToast(error.message, "error");
    },
  });

  const doRefresh = async () => {
    await refetch();
  };

  useEffect(() => {
    if (refresh) doRefresh().then();
  }, [refresh]);

  // Update rows and total count whenever data changes
  useEffect(() => {
    if (data && data.length > 0) {
      setItems(data[0]);
      setTotalCount(data[1]);
    }
  }, [data]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  const NoItemsText = () => {
    const noItemsText =
      filters?.searchString && filters?.searchString.length > 0
        ? `No ${service.baseEndpoint} found with name like ${filters.searchString}`
        : `No ${service.baseEndpoint} yet...`;
    return (
      <>
        <img
          alt="No Items"
          width="100"
          height="100"
          src="/cards.svg"
          style={{
            filter: "opacity(0.3)",
            margin: "0 auto",
            display: "block",
            width: "auto",
            height: "25svh",
          }}
        />
        <Typography textAlign="center" fontSize={14} display="block">
          {noItemsText}
        </Typography>
      </>
    );
  };

  if (error) {
    return <div>Error: {(error as Error).message}</div>;
  }

  return (
    <DataGrid
      data-test-id="test-table"
      className={className}
      density="comfortable"
      autoHeight={!height}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: filters.take,
          },
        },
      }}
      rowCount={totalCount}
      loading={isFetching}
      columnHeaderHeight={0}
      rows={items}
      getRowHeight={() => "auto"}
      columns={columns}
      onPaginationModelChange={handlePaginationModelChange}
      pageSizeOptions={[paginationModel.pageSize]}
      getRowSpacing={() => ({ top: 0, bottom: 10 })}
      paginationMode="server"
      disableDensitySelector
      disableRowSelectionOnClick
      disableColumnMenu={disableColumnMenu}
      disableColumnSelector={disableColumnSelector}
      disableColumnFilter={disableColumnFilter}
      checkboxSelection={checkboxSelection}
      filterMode="server"
      sortingMode="server"
      slots={{
        noRowsOverlay: NoItemsText,
        loadingOverlay: InlineLoadingComponent,
        row: CustomGridRow,
      }}
      hideFooterSelectedRowCount
      showColumnVerticalBorder={false}
      showCellVerticalBorder={false}
      onRowClick={(row) => {
        if (onRowClick) {
          onRowClick(row);
        }
      }}
      hideFooter={!items.length}
      getRowId={getRowId}
      sx={{
        height,
        width: "100%",
        "& .MuiDataGrid-overlayWrapper": {
          height: "200px !important",
          position: "absolute",
          top: 0,
          left: 0,
        },
      }}
    />
  );
};

export default TableComponent;
