import Grid from "@mui/material/Grid";
import { useAuth } from "../../core/context/auth.context";
import useScreenSize from "../../core/hooks/use-screen-size";
import useUserOs from "../../core/hooks/use-user-os";
import UserDebtsListComponent from "../user-debts-list.component";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import UserService from "../../core/service/user.service";
import formatCurrency from "../../core/helpers/format-currency.helper";

export default function MyDebtsTab() {
  const { user } = useAuth();
  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const innerContainerSize = screenSize.height - (isIOS ? 180 : 165); // magic numbers
  const userService = new UserService();

  const { data: userStats, refetch } = useQuery({
    queryKey: [`singleUserData`, { userId: user!.id }],
    queryFn: () => userService.getStats(user!.id),
    initialData: undefined,
  });

  const onDebtChange = async () => {
    await refetch();
  };

  if (!user) {
    return null;
  }
  return (
    <Grid
      item
      xs={12}
      height="80vh"
      style={{
        backgroundColor: "#aab1db",
        boxShadow: "1px 1px 3px rgb(0 0 0 / 40%)",
        padding: 15,
        borderRadius: 15,
      }}
    >
      <Typography
        textAlign="center"
        style={{
          textShadow: "none",
          fontWeight: 300,
          padding: 10,
          borderBottom: "1px solid rgba(255,255,255,.6)",
          marginBottom: 10,
          color: "#fff",
        }}
      >
        My total debts:{" "}
        <b style={{ color: "#47528c" }}>
          {formatCurrency(userStats?.data?.debts, "USD")}
        </b>
      </Typography>
      <UserDebtsListComponent
        height={innerContainerSize - 120}
        type="users"
        onDebtChange={onDebtChange}
        pageSize={Math.floor((innerContainerSize - 100) / 80)}
      />
    </Grid>
  );
}
