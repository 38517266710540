import { useEffect } from "react";
import { Socket } from "socket.io-client";

type EventCallback = (data?: any) => void;

interface SocketEvent {
  eventName: string;
  callback: EventCallback;
}

export const useSocketSubscription = (
  socket: Socket | null | undefined,
  events: SocketEvent[],
): void => {
  useEffect(() => {
    if (!socket) return;

    events.forEach(({ eventName, callback }) => {
      const eventHandler = (data: any) => {
        callback(data);
      };

      socket.on(eventName, eventHandler);

      // Cleanup when the component unmounts or dependencies change
      return () => {
        socket.off(eventName, eventHandler);
      };
    });
  }, [socket, events]);
};
