import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./auth.context";
import { getCookie } from "../helpers/storage.helper";
import { API_URL, TOKEN_COOKIE_NAME } from "../config";
import { WebSocketService } from "../service/socket.service";

interface SocketsListInterface {
  gameSocket: WebSocketService | null;
  tableSocket: WebSocketService | null;
  transactionSocket: WebSocketService | null;
}

interface SocketProviderProps {
  children: ReactNode;
}

const SocketContext = createContext<SocketsListInterface | undefined>(
  undefined,
);

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const [sockets, setSockets] = useState<SocketsListInterface>({
    gameSocket: null,
    tableSocket: null,
    transactionSocket: null,
  });

  useEffect(() => {
    if (user) {
      const token = getCookie(TOKEN_COOKIE_NAME);

      const gameSocket = new WebSocketService("/games", API_URL, token);
      const tableSocket = new WebSocketService("/tables", API_URL, token);
      const transactionSocket = new WebSocketService(
        "/transactions",
        API_URL,
        token,
      );

      gameSocket.connect();
      tableSocket.connect();
      transactionSocket.connect();

      setSockets({ gameSocket, tableSocket, transactionSocket });

      return () => {
        gameSocket.disconnect();
        tableSocket.disconnect();
        transactionSocket.disconnect();
      };
    }
  }, [user]);

  return (
    <SocketContext.Provider value={sockets}>{children}</SocketContext.Provider>
  );
};

export const useSocket = (): SocketsListInterface => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
